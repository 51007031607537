/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { fetchApiAuth } from 'core/utils/api';
import { handleError } from 'core/utils/helpers';
import { ResponseProps, CreateUserPayload } from './types';

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async () => {
        try {
            const response: ResponseProps = await fetchApiAuth({
                method: 'GET',
                url: '/user/users',
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            throw errorMessage;
        }
    },
);

export const createCareHome = createAsyncThunk(
    'careHome/create',
    async ({ title, local_authority_id }: { title: string; local_authority_id: number }) => {
        try {
            console.log('Payload for creating care home:', { title, local_authority_id });
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/assessment/care-home',
                body: { title, local_authority_id },
            });
            message.success('Care home created successfully');
            return response.data;
        } catch (error) {
            const errorMessage = handleError(error);
            message.error(errorMessage);
            throw errorMessage;
        }
    },
);

export const createUser = createAsyncThunk(
    'user/create',
    async (
        { name, email, jobTitle, role, care_home_id, local_authority_id }: CreateUserPayload,
        { rejectWithValue },
    ) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/user/create-user',
                body: { name, email, jobTitle, role, care_home_id, local_authority_id },
            });
            message.success('User created successfully');
            return response.data;
        } catch (error) {
            const errorMessage = handleError(error);
            message.error(errorMessage);
            return rejectWithValue(errorMessage);
        }
    },
);

export const deleteUser = createAsyncThunk(
    'user/delete',
    async (userId: number, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/user/delete-user',
                body: { id: userId },
            });
            message.success('User deleted successfully');
            return response.data;
        } catch (error) {
            const errorMessage = handleError(error);
            message.error(errorMessage);
            return rejectWithValue(errorMessage);
        }
    },
);

export const updateUser = createAsyncThunk(
    'user/update',
    async (
        { id, name, email, jobTitle, role, care_home_id, local_authority_id }: CreateUserPayload & { id: number },
        { rejectWithValue },
    ) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/user/update-user',
                body: { id, name, email, jobTitle, role, care_home_id, local_authority_id },
            });
            message.success('User updated successfully');
            return response.data;
        } catch (error) {
            const errorMessage = handleError(error);
            message.error(errorMessage);
            return rejectWithValue(errorMessage);
        }
    },
);
