import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import ForgotPasswordForm from 'components/Form/ForgotPasswordForm';
import icon from '../../assets/favicon.ico';
import '../Login/login.scss';
import constants from '../../core/constants';
import { ForgotPasswordProps } from './types';
import logoImage from '../../assets/images/logo-image.png';

const { APP_NAME } = constants;

const ForgotPassword:FC<ForgotPasswordProps> = ({ match }) => {

    const backLink = {
        link: '/',
        title: '',
    };

    console.log(match);

    return (
        <div className="login-wrap">
            <Helmet
                title={`${APP_NAME}`}
                titleTemplate={`${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <div className="login-info">
                <div className="bg-absolute ">
                    <img src={logoImage} alt={`${APP_NAME}`} />
                </div>
            </div>
            <ForgotPasswordForm backLink={backLink} />
        </div>
    );
};

export default ForgotPassword;
