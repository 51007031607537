/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import './graphComponent.scss';
import { GraphComponentProps } from './types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const GraphComponent: React.FC<GraphComponentProps> = ({ data }) => {
    const transformedData = useMemo(() => {
        if (!data || data.length === 0) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const labels = data.map(entry => entry.title);
        const completedData = data.map(entry => entry.completed);
        const reassessmentsData = data.map(entry => entry.reassessments);
        const abandonedData = data.map(entry => entry.abandoned);

        return {
            labels,
            datasets: [
                {
                    label: 'Completed',
                    data: completedData,
                    backgroundColor: 'rgba(106, 193, 123, 0.8)',
                    barThickness: 40,
                    borderRadius: 5,
                },
                {
                    label: 'Reassessments',
                    data: reassessmentsData,
                    backgroundColor: 'rgba(0, 174, 239, 0.8)',
                    barThickness: 40,
                    borderRadius: 5,
                },
                {
                    label: 'Abandoned',
                    data: abandonedData,
                    backgroundColor: 'rgba(34, 29, 35, 0.8)',
                    barThickness: 40,
                    borderRadius: 5,
                },
            ],
        };
    }, [data]);

    const options: ChartOptions<'bar'> = {
        plugins: {
            title: {
                display: true,
                font: {
                    weight: 'bold',
                },
            },
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    font: {
                        weight: 'bold',
                    },
                },
            },
        },
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Number of ISTUMBLE Processes',
                    font: {
                        size: 15,
                        weight: 'bold',
                    },
                },
                ticks: {
                    color: '#20639B',
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Care Home',
                    font: {
                        size: 15,
                        weight: 'bold',
                    },
                },
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    color: '#20639B',
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
            },
        },
    };

    return (
        <div className="bar-container">
            <Bar
                data={transformedData}
                options={options}
                width={400}
                height={300} />
        </div>
    );
};

export default GraphComponent;
