import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import DashboardHeader from 'components/DashboardHeader';
import Breakdown from 'components/Breakdown';
import Callout from 'components/Callout';
import Overview from 'components/Overview';
import { Roles } from 'core/constants';
import { fetchAssessmentData, fetchAssessmentBreakdown, fetchAssessmentCallOuts } from 'core/assessment/assessmentApi';
import Main from '../../components/layout/Main';
import { CombinedStates, AppDispatch } from '../../core/types';

import './dashboard.scss';

const formatTimeRange = (timeRange: string): string => {
    switch (timeRange.toLowerCase()) {
    case 'today':
        return 'Today';
    case 'week':
        return 'This Week';
    case 'month':
        return 'This Month';
    default:
        return timeRange;
    }
};
const Dashboard = () => {
    const userData = useSelector((state: CombinedStates) => state.user.userData);
    const role = userData && userData.role ? userData.role.name as Roles : Roles.ZONE_MANAGER;
    const careHomeId = userData ? userData.care_home_id : 0;
    const dispatch = useDispatch<AppDispatch>();
    const [selectedTimeRange, setTimeRange] = useState<string>('Today');
    const backLink = (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    const handleApply = useCallback((startDate: string, endDate: string, careHomeIds: number[], startTime: string, endTime: string, timeRange: string) => {
        const params = { careHomeId: careHomeIds, startDate, endDate, startTime, endTime };
        dispatch(fetchAssessmentData(params));
        dispatch(fetchAssessmentBreakdown(params));
        dispatch(fetchAssessmentCallOuts(params));

        const formattedTimeRange = formatTimeRange(timeRange);
        setTimeRange(formattedTimeRange);
    }, [dispatch]);

    return (
        <Main
            className="login"
            title={<div>Dashboard</div>}
            headerTitle={<div>Dashboard</div>}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2020</div>}
            backLink={backLink}>
            <DashboardHeader role={role} careHomeId={careHomeId} onApply={handleApply} />
            <Overview role={role} careHomeId={careHomeId} timeRange={selectedTimeRange} />
            <Breakdown role={role} careHomeId={careHomeId} />
            <Callout role={role} careHomeId={careHomeId} timeRange={selectedTimeRange} />
        </Main>
    );
};

export default Dashboard;
