/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAssessmentData, fetchAssessmentBreakdown, fetchAssessmentCallOuts, fetchCareHomes, fetchLocalAuthorities } from './assessmentApi';
import { AssessmentState, AssessmentData, AssessmentBreakdownItem, AssessmentCallOut, CareHome, LocalAuthority } from './types';

const initialState: AssessmentState = {
    data: null,
    breakdownData: null,
    callOuts: [],
    careHomes: [],
    localAuthorities: [],
    error: null,
    isFetching: false,
};

const assessmentSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssessmentData.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchAssessmentData.fulfilled, (state, action: PayloadAction<AssessmentData>) => {
                state.isFetching = false;
                state.data = action.payload;
            })
            .addCase(fetchAssessmentData.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch assessment data';
            })
            .addCase(fetchAssessmentBreakdown.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchAssessmentBreakdown.fulfilled, (state, action: PayloadAction<AssessmentBreakdownItem[]>) => {
                state.isFetching = false;
                state.breakdownData = action.payload;
            })
            .addCase(fetchAssessmentBreakdown.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch assessment breakdown';
            })
            .addCase(fetchAssessmentCallOuts.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchAssessmentCallOuts.fulfilled, (state, action: PayloadAction<AssessmentCallOut[]>) => {
                state.isFetching = false;
                state.callOuts = action.payload;
            })
            .addCase(fetchAssessmentCallOuts.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch assessment call outs';
            })
            .addCase(fetchCareHomes.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchCareHomes.fulfilled, (state, action: PayloadAction<CareHome[]>) => {
                state.isFetching = false;
                state.careHomes = action.payload;
            })
            .addCase(fetchCareHomes.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch care homes';
            })
            .addCase(fetchLocalAuthorities.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchLocalAuthorities.fulfilled, (state, action: PayloadAction<LocalAuthority[]>) => {
                state.isFetching = false;
                state.localAuthorities = action.payload;
            })
            .addCase(fetchLocalAuthorities.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch local authorities';
            });
    },
});

export default assessmentSlice.reducer;
