import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Routes from './core/routes';
// import history from './core/utils/history';
import DebugWrapper from './core/utils/debug';

import './styles/style.scss';
/** Actions: The necessary actions for dispatching our bootstrap values */
// import { setVersion } from './core/global/globalSlice';

/** configureStore */
import { store, persistor } from './core/store';

/** The version of the app but not displayed yet */
// import pack from '../package.json';

// const VERSION = pack.version;
// const dispatch = useDispatch();
//
// dispatch(setVersion(VERSION));

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <DebugWrapper>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter
                        // history={history}
                        basename="/">
                        <Routes />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </DebugWrapper>
    </React.StrictMode>,
);
