/* eslint-disable dot-notation */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';
import { AssessmentData, AssessmentBreakdownItem, ResponseProps, AssessmentCallOut, FetchParams, CareHome, LocalAuthority } from './types';
import { handleError } from '../utils/helpers';

export const fetchAssessmentData = createAsyncThunk<AssessmentData, FetchParams>(
    'assessment/fetchAssessmentData',
    async ({ careHomeId, startDate, endDate, startTime, endTime }, { rejectWithValue }) => {
        try {
            const params: Record<string, string | number | number[]> = {};
            if (careHomeId) params['care_home_id'] = careHomeId;
            if (startDate) params['start_date'] = startDate;
            if (endDate) params['end_date'] = endDate;
            if (startTime) params['start_time'] = startTime;
            if (endTime) params['end_time'] = endTime;
            const response: ResponseProps<AssessmentData> = await fetchApiAuth({
                method: 'GET',
                url: '/assessment/assessment-data',
                params,
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchAssessmentBreakdown = createAsyncThunk<AssessmentBreakdownItem[], FetchParams>(
    'assessment/fetchAssessmentBreakdown',
    async ({ careHomeId, startDate, endDate, startTime, endTime }, { rejectWithValue }) => {
        try {
            const params: Record<string, string | number | number[]> = {};
            if (careHomeId) params['care_home_id'] = careHomeId;
            if (startDate) params['start_date'] = startDate;
            if (endDate) params['end_date'] = endDate;
            if (startTime) params['start_time'] = startTime;
            if (endTime) params['end_time'] = endTime;

            const response: ResponseProps<AssessmentBreakdownItem[]> = await fetchApiAuth({
                method: 'GET',
                url: '/assessment/breakdown',
                params,
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchAssessmentCallOuts = createAsyncThunk<AssessmentCallOut[], FetchParams>(
    'assessment/fetchAssessmentCallOuts',
    async ({ careHomeId, startDate, endDate, startTime, endTime }, { rejectWithValue }) => {
        try {
            const params: Record<string, string | number | number[]> = {};
            if (careHomeId) params['care_home_id'] = careHomeId;
            if (startDate) params['start_date'] = startDate;
            if (endDate) params['end_date'] = endDate;
            if (startTime) params['start_time'] = startTime;
            if (endTime) params['end_time'] = endTime;

            const response: ResponseProps<AssessmentCallOut[]> = await fetchApiAuth({
                method: 'GET',
                url: '/assessment/call-outs',
                params,
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchCareHomes = createAsyncThunk<CareHome[], number | undefined>(
    'assessment/fetchCareHomes',
    async (localAuthorityId, { rejectWithValue }) => {
        try {
            const params = localAuthorityId ? { local_authority_id: localAuthorityId } : {};
            const response: ResponseProps<CareHome[]> = await fetchApiAuth({
                method: 'GET',
                url: '/assessment/care-homes',
                params,
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchLocalAuthorities = createAsyncThunk<LocalAuthority[]>(
    'assessment/fetchLocalAuthorities',
    async (_, { rejectWithValue }) => {
        try {
            const response: ResponseProps<LocalAuthority[]> = await fetchApiAuth({
                method: 'GET',
                url: '/assessment/local-authorities',
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
