import React from 'react';
import { Spin } from 'antd';
import './spinner.scss';

const Spinner = () => (
    <div className="spinner">
        <Spin />
    </div>
);

export default Spinner;
