import React, { FC, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { AppDispatch, CombinedStates } from '../../../core/types';
import { loginUser } from '../../../core/user/userApi';
import './loginForm.scss';
import { FinishedFailedProps, valuesProps } from './types';

const LoginForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const {
        isFetching,
        isLoggedIn,
    } = useSelector((state:CombinedStates) => state.user);

    const [form] = Form.useForm();

    const renderIcon = (visible:boolean) => (visible ? <EyeOutlined style={{ color: '#20639B', fontSize: '20px' }} /> : <EyeInvisibleOutlined style={{ color: '#20639B', fontSize: '20px' }} />);

    const onFinish = (values: valuesProps) => {
        dispatch(loginUser({
            email: values.email,
            password: values.password,
        }));
    };

    const onFinishFailed:FC<FinishedFailedProps> = ({ errorFields }) => {
        form.scrollToField(errorFields[0]?.name);
        return null;
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigation('/dashboard');
        }
    }, [isLoggedIn]);

    return (
        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title h-1">Login</h3>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical">
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                        <Input placeholder="Enter your email" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password.' }]}>
                        <Input.Password placeholder="Enter password" iconRender={renderIcon} />
                    </Form.Item>
                    <Button onClick={() => navigation('/forgot-password')} type="link" className="button-link">
                        Forgot password?
                    </Button>
                    <Form.Item>
                        {isFetching ? (
                            <Button size="large" htmlType="submit" block className="login-btn" loading>
                                Login
                            </Button>
                        ) : (
                            <Button size="large" htmlType="submit" block className="login-btn">
                                Login
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginForm;
