import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CombinedStates } from 'core/types';
import { Row, Col, Layout } from 'antd';
import Logout from 'components/Logout';
import { Logo } from '../../Elements/CustomSVGIcon';
import type { HeaderProps } from './types';

import './header.css';

const Header = (props: HeaderProps) => {
    const { floating, beforeHeader, afterHeader } = props;

    const userData = useSelector((state: CombinedStates) => state.user.userData);

    const role = userData && userData.role ? userData.role.name : null;

    const location = useLocation();

    const isActive = (path: string) => location.pathname === path;

    let links: JSX.Element[] = [];
    if (role === 'zone_admin') {
        links = [
            <a href="/dashboard" className={isActive('/dashboard') ? 'active-link' : ''} key="dashboard">Dashboard</a>,
            <a href="/news-builder" className={isActive('/news-builder') ? 'active-link' : ''} key="news-builder">News Builder</a>,
            <a href="/my-account" className={isActive('/my-account') ? 'active-link' : ''} key="my-account">My Account</a>,
            <a href="/settings" className={isActive('/settings') ? 'active-link' : ''} key="settings">Settings</a>,
        ];
    } else if (role === 'zone_manager') {
        links = [
            <a href="/dashboard" className={isActive('/dashboard') ? 'active-link' : ''} key="dashboard">Dashboard</a>,
            <a href="/my-account" className={isActive('/my-account') ? 'active-link' : ''} key="view-reports">My Account</a>,
        ];
    }

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row justify="space-between" align="middle" style={{ height: 'inherit' }}>
                    <Col>
                        <span className="logo"><Logo /></span>
                    </Col>
                    <Col>
                        {links.map(link => (
                            <Col style={{ marginRight: '50px', display: 'inline-block' }}>{link}</Col>
                        ))}
                    </Col>
                    <Col>
                        <Row align="middle">
                            <Logout />
                        </Row>
                    </Col>
                </Row>
            </Layout.Header>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    logo: '',
    beforeHeader: null,
    afterHeader: null,
};

export default Header;
