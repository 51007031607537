import React, { FC } from 'react';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon, AltCheckboxFill } from '../Elements/CustomSVGIcon';
import './checkbox.scss';

interface CheckboxProps {
    onChange: () => void; // Changed from onClick to onChange for clarity
    checked: boolean;
    disabled?: boolean;
    icon?: 'tick' | 'block';
}

const Checkbox: FC<CheckboxProps> = ({ onChange, checked, disabled, icon = 'tick' }) => {
    const getCheckedIcon = () => {
        switch (icon) {
        case 'block':
            return <AltCheckboxFill />;
        default:
            return <CheckboxCheckedIcon />;
        }
    };

    return (
        <div className={`checkbox-container ${checked ? 'active' : 'inactive'}`} onClick={!disabled ? onChange : undefined}>
            {checked ? getCheckedIcon() : <CheckboxUncheckedIcon />}
        </div>
    );
};

Checkbox.defaultProps = {
    disabled: false,
    icon: 'tick',
};

export default Checkbox;
