import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import icon from '../../../assets/favicon.ico';
import logoImage from '../../../assets/images/logo-image.png';
import './authLayout.scss';
import constants from '../../../core/constants';
import { LayoutProps } from './types';

const { APP_NAME } = constants;

const AuthLayout:FC<LayoutProps> = ({ children }) => (
    <div className="login-wrap">
        <Helmet
            title={`${APP_NAME}`}
            titleTemplate={`${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' },
            ]}>
            <link rel="shortcut icon" href={icon} />
        </Helmet>
        <div className="login-info">
            <div className="bg-absolute ">
                <img src={logoImage} alt={`${APP_NAME}`} />
            </div>
        </div>
        {children}
    </div>
);

export default AuthLayout;
