import React from 'react';
import { Tag } from 'antd';
import { SelectedCareHomesProps, CareHomeOption } from './types';
import './selectedCareHomes.scss';

const SelectedCareHomes: React.FC<SelectedCareHomesProps> = ({ selectedCareHomes, onRemove }) => (
    <div className="selected-care-homes-container">
        {selectedCareHomes.length > 0 && (
            <span className="filter-label">Filters Applied: </span>
        )}
        {selectedCareHomes.map((careHome: CareHomeOption) => (
            <Tag
                key={careHome.value}
                closable
                onClose={() => onRemove(careHome)}
                className="care-home-tag">
                {careHome.label}
            </Tag>
        ))}
    </div>
);

export default SelectedCareHomes;
