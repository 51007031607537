import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeOutlined, CheckCircleFilled } from '@ant-design/icons';
import { resetPassword } from '../../../core/user/userApi';
import './createPasswordForm.scss';
import { FinishedFailedProps, valuesProps, CreatePasswordFormProps } from './types';
import { AppDispatch, CombinedStates } from '../../../core/types';

const CreatePasswordForm:FC<CreatePasswordFormProps> = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [password, setPassword] = useState('');
    const {
        isFetching,
    } = useSelector((state:CombinedStates) => state.user);
    const { token, email } = useParams();
    const [form] = Form.useForm();

    const validatePassword = (inputPassword: string) => ({
        minLength: inputPassword.length >= 8,
        numberAndLetter: /[A-Za-z]/.test(inputPassword) && /[0-9]/.test(inputPassword),
        upperCase: /[A-Z]/.test(inputPassword),
    });
    const passwordStatus = validatePassword(password);

    const onFinish = (values: valuesProps) => {
        dispatch(resetPassword({
            email,
            password: values.newPassword,
            passwordConfirmation: values.confirmPassword,
            token,
        }));
    };

    const renderIcon = (visible:boolean) => (visible ? <EyeOutlined style={{ color: '#20639B', fontSize: '20px' }} /> : <EyeInvisibleOutlined style={{ color: '#20639B', fontSize: '20px' }} />);

    const onFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    return (

        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title h-1">Create Password</h3>
                <p className="sub-title">To complete the creation of your account, please enter a password you wish to use to access to your account</p>
                <Form onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
                    <Form.Item label="Password"
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Password is required' },
                            () => ({
                                validator(_, value) {
                                    setPassword(value || '');
                                    const passwordValidation = validatePassword(value);
                                    if (!value || passwordValidation.minLength && passwordValidation.numberAndLetter && passwordValidation.upperCase) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Password does not meet requirements'));
                                },
                            }),
                        ]}>
                        <Input.Password iconRender={renderIcon} />
                    </Form.Item>
                    <div className="container-one">
                        <h3 className="password-requirements">Password must:</h3>
                        <ul className="password-criteria">
                            <li style={{ color: passwordStatus.minLength ? 'green' : 'black' }}>
                                {passwordStatus.minLength ? <CheckCircleFilled /> : <CheckCircleFilled />} At least 8 characters long
                            </li>
                            <li style={{ color: passwordStatus.numberAndLetter ? 'green' : 'black' }}>
                                {passwordStatus.numberAndLetter ? <CheckCircleFilled /> : <CheckCircleFilled />} Be a mixture of letters and numbers
                            </li>
                            <li style={{ color: passwordStatus.upperCase ? 'green' : 'black' }}>
                                {passwordStatus.upperCase ? <CheckCircleFilled /> : <CheckCircleFilled />} Contain at least one uppercase letter
                            </li>
                        </ul>
                    </div>
                    <Form.Item label="Verify Password"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}>
                        <Input.Password iconRender={renderIcon} />
                    </Form.Item>
                    <Row>
                        <Col xs={24}>
                            <Button size="large" block htmlType="submit" className="save-btn" loading={isFetching}>
                                Create Account
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </div>

    );
};

export default CreatePasswordForm;
