/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import React from 'react';
import { RadioCheckedIcon, RadioUncheckedIcon } from '../Elements/CustomSVGIcon';
import './radio.scss';

interface RadioProps<T> {
    onChange: (value: T) => void;
    checked: boolean;
    disabled?: boolean;
    label: string;
    value: T;
    icon?: 'circle' | 'dot';
}

const Radio = <T extends unknown>({ onChange, checked, disabled, value, label, icon = 'dot' }: RadioProps<T>) => {
    const handleClick = () => {
        if (!disabled && onChange) {
            onChange(value);
        }
    };

    const getCheckedIcon = () => {
        switch (icon) {
        case 'dot':
            return <RadioCheckedIcon />;
        default:
            return <RadioCheckedIcon />;
        }
    };

    return (
        <div className={`radio-container ${checked ? 'active' : 'inactive'}`} onClick={handleClick}>
            {checked ? getCheckedIcon() : <RadioUncheckedIcon />}
            <span className="radio-label">{label}</span>
        </div>
    );
};

Radio.defaultProps = {
    disabled: false,
    icon: 'dot',
};

export default Radio;
