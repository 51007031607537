/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input } from 'antd';
import { CalendarOutlined, DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';
import { DownIcon } from 'components/Elements/CustomSVGIcon';
import { Calendar, DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import Radio from 'components/Radio';
import Dropdown from 'components/CheckboxDropdown';
import { Roles } from 'core/constants';
import { fetchCareHomes } from 'core/assessment/assessmentApi';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedStates, AppDispatch } from 'core/types';
import SelectedCareHomes from 'components/SelectedCareHomes';
import './dashboardHeader.scss';

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ role, careHomeId, onApply }) => {
    const dispatch = useDispatch<AppDispatch>();
    const careHomes = useSelector((state: CombinedStates) => state.assessment.careHomes);
    const [selectedCareHomes, setSelectedCareHomes] = useState<CareHomeOption[]>([]);
    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
    const [startTime, setStartTime] = useState<string>('09:00');
    const [endTime, setEndTime] = useState<string>('17:00');
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const [dateRangeOption, setDateRangeOption] = useState<string>('today');

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (role !== Roles.ZONE_MANAGER) {
            dispatch(fetchCareHomes());
        }
    }, [dispatch, role]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownVisible(false);
            }
        };

        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    const careHomeOptions: CareHomeOption[] = (careHomes ?? []).map(careHome => ({
        label: careHome.title,
        value: careHome.id.toString(),
    }));

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleDateShortcut = (value: string) => {
        if (value === 'week') {
            setSelectedDates([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        } else if (value === 'today') {
            const today = new Date();
            setSelectedDates([today, today]);
        } else if (value === 'month') {
            setSelectedDates([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        }
        setDateRangeOption(value);
    };

    const handleRadioChange = (value: string) => {
        handleDateShortcut(value);
    };

    const getDateRangeLabel = () => {
        const start = moment(selectedDates[0]).startOf('day');
        const end = moment(selectedDates[1]).endOf('day');

        const isToday = start.isSame(moment().startOf('day')) && end.isSame(moment().endOf('day'));
        const isThisWeek = start.isSame(moment().startOf('week')) && end.isSame(moment().endOf('week'));
        const isThisMonth = start.isSame(moment().startOf('month')) && end.isSame(moment().endOf('month'));

        if (isToday) return 'Today';
        if (isThisWeek) return 'This Week';
        if (isThisMonth) return 'This Month';

        // Set the option as custom but display the date range
        return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
    };

    const handleCustomDateSelection = (dates: DateObject[]) => {
        setSelectedDates(dates.map(date => date.toDate()));
        setDateRangeOption('custom');
    };

    const options = [
        { label: 'Today', value: 'today' },
        { label: 'This Week', value: 'week' },
        { label: 'This Month', value: 'month' },
        { label: 'Custom', value: 'custom' },
    ];

    const handleCareHomeSelect = (values: CareHomeOption[]) => {
        setSelectedCareHomes(values);
    };

    const handleRemoveCareHome = (careHomeToRemove: CareHomeOption) => {
        setSelectedCareHomes(selectedCareHomes.filter(careHome => careHome.value !== careHomeToRemove.value));
    };

    const handleApplyClick = () => {
        const formattedStartDate = moment(selectedDates[0]).format('YYYY-MM-DD');
        const formattedEndDate = moment(selectedDates[1]).format('YYYY-MM-DD');
        const selectedCareHomeIds = selectedCareHomes.map(ch => parseInt(ch.value, 10));

        const careHomeIds = role === Roles.ZONE_MANAGER ? [careHomeId] : selectedCareHomeIds;
        const formattedStartTime = `${startTime}:00`;
        const formattedEndTime = `${endTime}:00`;

        const timeRange = dateRangeOption === 'custom'
            ? `${formattedStartDate} to ${formattedEndDate}`
            : dateRangeOption;

        onApply(formattedStartDate, formattedEndDate, careHomeIds, formattedStartTime, formattedEndTime, timeRange);
        setIsDropdownVisible(false);
    };

    return (
        <>
            <Row justify="space-between" align="middle" className="dashboard-header">
                <Col flex="auto" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div className="dropdown-container">
                        <Button onClick={toggleDropdown} className="button">
                            {getDateRangeLabel()} <CalendarOutlined style={{ marginLeft: '100px' }} />
                        </Button>
                        {isDropdownVisible && (
                            <div ref={dropdownRef} className="custom-dropdown">
                                <Row gutter={[16, 16]}>
                                    {/* Radio Group Column */}
                                    <Col span={6}>
                                        <div className="radio-group">
                                            {options.map(option => (
                                                <Radio
                                                    key={option.value}
                                                    value={option.value}
                                                    checked={dateRangeOption === option.value}
                                                    onChange={handleRadioChange}
                                                    label={option.label} />
                                            ))}
                                        </div>
                                    </Col>

                                    {/* Date Picker Column */}
                                    <Col span={10} className="date-container">
                                        <h3 className="date-title">Date</h3>
                                        <Calendar
                                            value={selectedDates as unknown as DateObject[]}
                                            onChange={handleCustomDateSelection}
                                            range
                                            className="date-picker" />
                                    </Col>

                                    <Col span={8}>
                                        <div className="time-input-container">
                                            <h3 className="time-title">Time Frame</h3>
                                            <label className="label">Start Time</label>
                                            <div className="custom-time-input">
                                                <Input type="time" value={startTime} onChange={e => setStartTime(e.target.value)} className="time-input" />
                                                <span className="down-arrow-icon"><DownIcon /></span>
                                            </div>
                                            <label className="label">End Time</label>
                                            <div className="custom-time-input">
                                                <Input type="time" value={endTime} onChange={e => setEndTime(e.target.value)} className="time-input" />
                                                <span className="down-arrow-icon"><DownIcon /></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row justify="center" className="containerOne">
                                    <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <Button type="primary" className="applyButton" onClick={handleApplyClick}>Apply</Button>
                                        <Button className="resetButton">Reset</Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                    {role !== Roles.ZONE_MANAGER && (
                        <Dropdown
                            options={careHomeOptions}
                            onSelect={handleCareHomeSelect}
                            values={selectedCareHomes} />
                    )}
                    <Button className="download-button" icon={<DownloadOutlined />}>
                        <span>Download Report</span>
                        <span className="caret-container">
                            <CaretDownOutlined style={{ marginLeft: '10px' }} />
                        </span>
                    </Button>
                </Col>
            </Row>
            {role !== Roles.ZONE_MANAGER && (
                <SelectedCareHomes selectedCareHomes={selectedCareHomes} onRemove={handleRemoveCareHome} />
            )}
        </>
    );
};

export default DashboardHeader;
