import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogoutIcon } from 'components/Elements/CustomSVGIcon';
import { logoutUser } from '../../core/user/userApi';
import './logout.scss';
import { AppDispatch, CombinedStates } from '../../core/types';

const Logout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { isFetching } = useSelector((state: CombinedStates) => state.user);

    const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!isFetching) {
            await dispatch(logoutUser());
            navigate('/');
        }
    };

    return (
        <a href="/logout" onClick={handleLogout} className="logout-link">
            <span style={{ marginTop: '3px', marginRight: '5px' }}>
                <LogoutIcon />
            </span>
            Logout
        </a>
    );
};

export default Logout;
