import React, { FC } from 'react';
import ForgotPassword from 'pages/ForgotPassword';
import CreatePassword from 'pages/CreatePassword';
import MyAccount from 'pages/MyAccount';
import { CoreRouter, LogoutRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ResetPassword from '../pages/ResetPassword';
import TwoFactorAuth from '../pages/TwoFactorAuth';

const Routes: FC = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/dashboard', component: Dashboard, type: 'auth' },
        { path: '/my-account', component: MyAccount, type: 'auth' },
        { path: '/reset-password/:token/:email', component: ResetPassword, type: 'default' },
        { path: '/create-password/:token/:email', component: CreatePassword, type: 'default' },
        { path: '/forgot-password', component: ForgotPassword, type: 'default' },
        { path: '/two-factor', component: TwoFactorAuth, type: 'default' },
        { path: '/logout', component: LogoutRoute, type: 'default' },
    ]} />
);

export default Routes;
