/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, Progress } from 'antd';
import { GuideIcon, RunIcon, CallIcon, TrackIcon } from 'components/Elements/CustomSVGIcon';
import { fetchAssessmentData } from 'core/assessment/assessmentApi';
import { Roles } from 'core/constants';
import { CombinedStates, AppDispatch } from '../../core/types';
import './overview.scss';

const Overview: React.FC<OverviewProps> = ({ role, careHomeId, timeRange }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useSelector((state: CombinedStates) => state.assessment);

    useEffect(() => {
        if (role === Roles.ZONE_MANAGER && careHomeId) {
            dispatch(fetchAssessmentData({ careHomeId: [careHomeId] }));
        } else {
            dispatch(fetchAssessmentData({}));
        }
    }, [dispatch, role, careHomeId]);

    const cardData = [
        { id: 1, icon: <GuideIcon />, number: data?.['iStumble Fall Guides'] || 0, title: 'ISTUMBLE Fall Guides' },
        { id: 2, icon: <RunIcon />, number: data?.['Patient Falls'] || 0, title: 'Patient Falls' },
        { id: 3, icon: <CallIcon />, number: data?.['Emergency Service Calls'] || 0, title: 'Emergency Service Calls' },
        { id: 4, icon: <TrackIcon />, number: data?.['Hospital Transfers'] || 0, title: 'Hospital Transfers' },
    ];

    const circleData = [
        { id: 1, number: data?.['Patients Lifted Safely'] || 0, total: data?.['iStumble Fall Guides'] || 500, description: 'Patient Lifted Safely', timeRange },
        { id: 2, number: data?.['Patients Injured'] || 0, total: data?.['iStumble Fall Guides'] || 500, description: 'Patients Injured', timeRange },
        { id: 3, number: data?.['Number of Re-Assessments'] || 0, total: data?.['iStumble Fall Guides'] || 500, description: 'Number of Re-Assessments', timeRange },
    ];

    return (
        <div className="overview">
            <p className="header">Overview</p>
            <Row gutter={[16, 16]}>
                {cardData.map((item) => (
                    <Col key={item.id} xs={24} sm={12} md={6}>
                        <Card className="overview-card">
                            <div className="card-content">
                                <div className="icon-circle">{item.icon}</div>
                                <div className="card-details">
                                    <div className="number">{item.number}</div>
                                    <div>{item.title}</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row gutter={[16, 16]} className="circle-section">
                {circleData.map((circleItem) => (
                    <Col key={circleItem.id} xs={24} sm={12} md={8}>
                        <Card>
                            <div className="circle-container">
                                <Progress
                                    type="circle"
                                    percent={(circleItem.number / circleItem.total) * 100}
                                    size={120}
                                    format={() => <span className="number">{circleItem.number}</span>}
                                    strokeColor="#0061A0"
                                    strokeWidth={10} />

                                <div className="circle-text">
                                    <div className="text">{circleItem.description}</div>
                                    <div className="date-text">{circleItem.timeRange}</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Overview;
