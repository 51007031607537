import { combineReducers } from 'redux';
import global from './global/globalSlice';
import user from './user/userSlice';
import assessment from './assessment/assessmentSlice';
import account from './account/accountSlice';
/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    assessment,
    account,
});
export default appReducers;
