import { ErrorResponse } from '../user/types';

const handleError = (error: unknown): string | false => {
    if (typeof error === 'object' && error !== null && 'response' in error && 'data' in (error as ErrorResponse).response) {
        const serverError = error as ErrorResponse;
        return serverError.response?.data?.message ?? 'Error';
    }
    return false;
};

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };

    const day = date.getDate();

    const getDaySuffix = (dayValue: number) => {
        if (dayValue > 3 && dayValue < 21) return 'th';
        switch (dayValue % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
        }
    };

    return `${day}${getDaySuffix(day)} ${date.toLocaleDateString('en-GB', options)}`;
};

export { handleError, formatDate };
